// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__recent-activity___IKbbD {
  padding: 1rem 0;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 767px) {
  .styles__recent-activity___IKbbD {
    grid-template-columns: 1fr 1fr 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/external/provider/admin/RecentActivityStatBoxes/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EAEA,aAAA;EACA,SAAA;EACA,0BAAA;EACA,WAAA;EACA,sBAAA;AAAF;;AAGA;EACE;IACE,kCAAA;EAAF;AACF","sourcesContent":[".recent-activity {\n  padding: 1rem 0;\n\n  display: grid;\n  gap: 1rem;\n  grid-template-columns: 1fr;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n@media (min-width: 767px) {\n  .recent-activity {\n    grid-template-columns: 1fr 1fr 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recent-activity": `styles__recent-activity___IKbbD`
};
export default ___CSS_LOADER_EXPORT___;
