// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__timer___pl5eQ {
  padding: 4px 7px;
  width: 40px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  font-size: 11px;
  line-height: 1;
  color: white;
  background-color: rgba(46, 49, 54, 0.9);
}`, "",{"version":3,"sources":["webpack://./app/components/auth/poh/Timer/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EAEA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,mBAAA;EAEA,eAAA;EACA,cAAA;EAEA,YAAA;EACA,uCAAA;AAHF","sourcesContent":[".timer {\n  padding: 4px 7px;\n\n  width: 40px;\n  margin: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  border-radius: 35px;\n\n  font-size: 11px;\n  line-height: 1;\n\n  color: white;\n  background-color: rgba(46, 49, 54, 0.9);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer": `styles__timer___pl5eQ`
};
export default ___CSS_LOADER_EXPORT___;
