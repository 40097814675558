// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__video-wrap___IwFF4 {
  position: relative;
  padding-bottom: 100%;
}
.styles__video-wrap_webcam___icysp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  object-fit: fill;
}
.styles__video-wrap_canvas___Nmve3 > canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  pointer-events: none;
}
.styles__video-wrap_timer___IABgM {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 12px;
  z-index: 10;
}
.styles__video-wrap_info___ae0Ka {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}
.styles__video-wrap_group___UDuBa {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.styles__video-wrap_group___UDuBa button {
  bottom: 0 !important;
}
.styles__video-wrap_preview-button___C9s5w {
  position: absolute;
  right: calc(50% + 2.5rem);
}

@media screen and (min-width: 480px) {
  .styles__video-wrap___IwFF4 {
    padding-bottom: 75%;
  }
}
@media screen and (min-width: 768px) {
  .styles__video-wrap_group___UDuBa {
    bottom: 8px;
  }
}`, "",{"version":3,"sources":["webpack://./app/components/auth/poh/VideoWrap/styles.scss"],"names":[],"mappings":"AAaA;EACE,kBAAA;EACA,oBAAA;AAZF;AAcE;EAhBA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EAeE,YAAA;EACA,YAAA;EACA,gBAAA;AATJ;AAYE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EAEA,YAAA;EACA,oBAAA;AAXJ;AAcE;EAzBA,kBAAA;EACA,SAAA;EACA,2BAAA;EAyBE,SAAA;EACA,WAAA;AAVJ;AAaE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;AAXJ;AAcE;EAtCA,kBAAA;EACA,SAAA;EACA,2BAAA;EAsCE,UAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAVJ;AAYI;EACE,oBAAA;AAVN;AAcE;EACE,kBAAA;EACA,yBAAA;AAZJ;;AAgBA;EACE;IACE,mBAAA;EAbF;AACF;AAgBA;EAEI;IACE,WAAA;EAfJ;AACF","sourcesContent":["@mixin absolute-full {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n}\n\n@mixin absolute-center {\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.video-wrap {\n  position: relative;\n  padding-bottom: 100%;\n\n  &_webcam {\n    @include absolute-full;\n    height: 100%;\n    margin: auto;\n    object-fit: fill;\n  }\n\n  &_canvas > canvas {\n    position: absolute;\n    top: 0;\n    left: 0;\n\n    z-index: 999;\n    pointer-events: none;\n  }\n\n  &_timer {\n    @include absolute-center;\n    top: 12px;\n    z-index: 10;\n  }\n\n  &_info {\n    position: absolute;\n    right: 10px;\n    top: 10px;\n    z-index: 10;\n  }\n\n  &_group {\n    @include absolute-center;\n    z-index: 1;\n    bottom: 5px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    & button {\n      bottom: 0 !important; // this is very bad code, it's fix CaptureButton hardcode styles;\n    }\n  }\n\n  &_preview-button {\n    position: absolute;\n    right: calc(50% + 2.5rem);\n  }\n}\n\n@media screen and (min-width: 480px) {\n  .video-wrap {\n    padding-bottom: 75%;\n  }\n}\n\n@media screen and (min-width: 768px) {\n  .video-wrap {\n    &_group {\n      bottom: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video-wrap": `styles__video-wrap___IwFF4`,
	"video-wrap_webcam": `styles__video-wrap_webcam___icysp`,
	"video-wrap_canvas": `styles__video-wrap_canvas___Nmve3`,
	"video-wrap_timer": `styles__video-wrap_timer___IABgM`,
	"video-wrap_info": `styles__video-wrap_info___ae0Ka`,
	"video-wrap_group": `styles__video-wrap_group___UDuBa`,
	"video-wrap_preview-button": `styles__video-wrap_preview-button___C9s5w`
};
export default ___CSS_LOADER_EXPORT___;
